/* latin-ext */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/4a8cf5ca92d896fc-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/a8951d2551563083-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/e694f405aff3ad80-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/57054a76eea935c7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/2c1c4d7ba2598616-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/9a8284d7b5bfd87e-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/32b50f0692c362e7-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/889b60e75a2ad383-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/8e68762fc300506b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/068fc0d4384f5acc-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/dcfd2c883261b0fd-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/14b709cf7b2f0835-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/c924141e967ff35b-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/ab73c4f2ca1e6f27-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/6b86175068056293-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Blinker_032bce';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/0894907a01e10ee7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Blinker_Fallback_032bce';src: local("Arial");ascent-override: 103.88%;descent-override: 25.97%;line-gap-override: 0.00%;size-adjust: 92.42%
}.__className_032bce {font-family: '__Blinker_032bce', '__Blinker_Fallback_032bce';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/a9b61b60c2d733b4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a9b61b60c2d733b4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a9b61b60c2d733b4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a9b61b60c2d733b4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a9b61b60c2d733b4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a9b61b60c2d733b4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/a9b61b60c2d733b4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/bccb245ee3362e85-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/4117e16a89310b23-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/2a041df68de43fa6-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/17c5807503b61094-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/7572a3f8628c1f19-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/9c20d643cc03e499-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_e6c7c6';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/a9b61b60c2d733b4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Sans_3_Fallback_e6c7c6';src: local("Arial");ascent-override: 109.21%;descent-override: 42.66%;line-gap-override: 0.00%;size-adjust: 93.76%
}.__className_e6c7c6 {font-family: '__Source_Sans_3_e6c7c6', '__Source_Sans_3_Fallback_e6c7c6';font-style: normal
}

